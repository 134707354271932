.custom-home__header {
    background-color: #232f3e;
  }
  
  .custom-home__header-title {
    color: white;
  }
  
  .custom-home__category,
  .custom-home__header-sub-title {
    color: white;
  }
  
  @media (min-width: 992px) {
    .custom-home__sidebar {
      margin-top: -6rem;
    }
  }
  
  .custom-home__header-title > * {
    max-width: 70rem;
  }
  
  .custom-home-image__placeholder:before {
    content: "X";
    display: block;
    background-color: #232f3e;
    color: #232f3e;
    text-align: center;
    font-size: 40rem;
    line-height: 40rem;
  }
  
  ul.custom-list-separator {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  ul.custom-list-separator li {
    border-top: 1px solid #232f3e;
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  ul.custom-list-separator li:first-child {
    padding-top: 0;
    border-top: none;
  }
  
  ul.custom-list-separator li:last-child {
    padding-bottom: 0;
  }
  