.home {
  margin: -16px -40px;
  padding: 16px 40px;
  background-color: #323232;
}

.home-banner {
  padding-top: 32px;
  
}

.home-banner h1 {
  color: white;
  margin-bottom: 16px;
  font-size: 36px;
}

.home-banner h2 {
  margin-top: 16px;
  color: white;
  font-size: 30px;
  line-height: 30px;
  font-weight: normal;
}

.home-banner h6 {
  color: white;
  font-size: 14px;
  font-weight: normal;
}

